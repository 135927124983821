import React from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { 
  Row, 
  Col, 
  Container, 
  Card, 
  CardBody, 
  CardText, 
  CardTitle 
} from "reactstrap"
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"

const Services = () => {
  const data = useStaticQuery(graphql`
  query {
    allFile (filter: {relativeDirectory: {eq: "services"}}, sort: {fields: name}) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const services_images = data.allFile.edges.map(({node}) => {
    return(
      <Img fluid={node.childImageSharp.fluid} alt="0" />
    )
  })

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Servizi" keywords={[`auto`, `riparazioni`, `gommista`, `elettrauto`,`gomme`,`carroattrezzi`,`climatizzatore`,`autoriparazioni`,]} />
      <Container className="text-center mt-5">
        <div id="first">
        <Row>
          <Col className="mb-5" md="4">
            <Card className="shadow" style={{ width: '100%' }}>
              {services_images['0']}
              <CardBody>
                <CardTitle><h5>Vetture</h5></CardTitle>
                <CardText className="text-justify">
                Uno dei principali servizi che offriamo è il ripristino della funzionalità delle parti meccaniche delle automobili, tra cui motore e trasmissione. Grazie ad apposite attrezzature di diagnosi e al continuo aggiornamento dei meccanici, i nostri tecnici specializzati sono in grado di intervenire sulle vetture di tutti i principali produttori, rendendo la nostra officina un vero e propri centro di assistenza multimarca.                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5" md="4">
            <Card className="shadow" style={{ width: '100%' }}>
            {services_images['1']}
              <CardBody>
                <CardTitle><h5>Fuoristrada</h5></CardTitle>
                <CardText className="text-justify">
                La riparazione dei fuoristrada fa parte del nostro DNA da sempre, queste auto si differenziano dalle semplici autovetture per la loro capacità di muoversi oltre che sulle strade asfaltate, anche al di fuori di esse, su fondi accidentati, a forte pendenza e a scarsa aderenza. Saremo in grado di trovare la soluzione migliore per voi, sia che vogliate affrontare semplici percorsi, offroad amatoriali, percorsi impervi o raid africani.                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5" md="4">
            <Card className="shadow" style={{ width: '100%' }}>
            {services_images['2']}
                <CardBody>
                  <CardTitle><h5>Veicoli commerciali</h5></CardTitle>
                  <CardText className="text-justify">
                  Il nostro centro è attrezzato per la manutenzione ordinaria e straordinaria di mezzi aziendali. Questa tipologia di veicolo deve garantire affidabilità nel corso del tempo, dato che quasi sicuramente vi accompagnerà per molti chilometri. Effettueremo riparazioni su furgoni di qualsiasi marca con danni di qualsiasi entità.</CardText>
                </CardBody>
              </Card>
          </Col>
        </Row>
        </div>
        <div id="second">
        <Row>
          <Col className="mb-5" md="6">
            <Card className="shadow" style={{ width: '100%' }}>
            {services_images['3']}
              <CardBody>
                <CardTitle><h5>Gommista</h5></CardTitle>
                <CardText className="text-justify">
                Gli pneumatici sono uno dei componenti più importanti del tuo veicolo quando è in gioco la tua sicurezza.
Pneumatici migliori garantiscono un viaggio sicuro e confortevole. Uno pneumatico di scarsa qualità può influenzare negativamente le prestazioni generali del veicolo aumentando gli spazi di frenata, soprattutto sul bagnato e peggiorando la guidabilità. Non va poi dimenticato che pneumatici con una pressione inadeguata aumentano il consumo di carburante, le emissioni di CO2 e si usurano prematuramente e irregolarmente.                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5" md="6">
            <Card className="shadow" style={{ width: '100%' }}>
            {services_images['4']}
              <CardBody>
                <CardTitle><h5>Elettrauto</h5></CardTitle>
                <CardText className="text-justify">
                In caso di malfunzionamenti e guasti che riguardano l'impianto elettrico ed elettronico di un veicolo, effettuiamo una diagnostica del sistema coinvolto. Grazie a strumenti di diagnosi per lo più informatizzati, individueremo le origini del guasto e stimeremo tempi e costi dell'intervento necessario per riparare il veicolo. Nel caso ci siano componenti elettrici danneggiati, ci occuperemo di ordinare i ricambi, rimuovere gli elementi difettosi e sostituirli. Una volta completata la riparazione, effettueremo test di laboratorio, collaudi e prove su strada per verificare l'efficacia della riparazione e assicurarci che il veicolo sia perfettamente funzionante.                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </div>
        <div id="third">
        <Row>
          <Col className="mb-5" md="4">
          <Card className="shadow" style={{ width: '100%' }}>
            {services_images['8']}
            <CardBody>
              <CardTitle><h5>Manutenzione e riparazioni cambi automatici</h5></CardTitle>
                <CardText className="text-justify">
                Le autovetture dotate di cambio automatico sono sempre più diffuse, e sempre più automobilisti (soprattutto coloro che utilizzano la macchina prevalentemente in città) optano per una soluzione di questo tipo. Il nostro consiglio è quello di far controllare il cambio regolarmente, e di fare attenzione ad eventuali sintomi di malfunzionamento, fra cui: cambi di marcia lenti e meno precisi, cali di potenza in salita e aumento dei consumi                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5" md="4">
            <Card className="shadow" style={{ width: '100%' }}>
            {services_images['6']}
              <CardBody>
                <CardTitle><h5>Assistenza aria condizionata</h5></CardTitle>
                <CardText className="text-justify">
                Specialmente nei torridi mesi estivi il cilmatizzatore della nostra auto è diventato un comodissimo alleato. Non è da trascurare però la sua manutenzione periodica:  se l'aria che fuoriesce non è più fredda e se il tempo  necessario a rinfrescare l'abitacolo è troppo, sicuramente il vostro impianto di climatizzazione ha bisogno di un check-up. Le cause poi possono essere diverse e non solo dipendenti dall'assenze del gas refrigerante come falle all'impianto, intasamento del filtro antipolline (il quale però deve venire sostituito periodicamente durante il tagliando auto) e problemi al compressore.                </CardText>
                </CardBody>
            </Card>
          </Col>
          <Col className="mb-5" md="4">
            <Card className="shadow" style={{ width: '100%' }}>
            {services_images['7']}
              <CardBody>
                <CardTitle><h5>Recupero carroattrezzi</h5></CardTitle>
                <CardText className="text-justify">
                Che si tratti di una foratura, un guasto o un banale imprevisto, quando si è in strada sulla propria auto i rischi sono dietro l’angolo. Ecco perché tra i nostri servizi è presente anche il soccorso stradale. Non importa se in città, su una superstrada o in autostrada, in una giornata di sole o sotto la pioggia battente, il nostro servizio di carro attrezzi sarà in grado di venire in vostro soccorso.                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </div>
      </Container>
    </Layout>
  )
}

export default Services